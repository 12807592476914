<template>
  <b-card>
    <training-courses-edit-form
      :training-course-data="trainingCourseData"
      :loading="loading"
      :send-information-email.sync="sendInformationEmail"
      @submit="submit"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TrainingCoursesEditForm from './TrainingCoursesEditForm.vue'

export default {
  components: {
    BCard,
    TrainingCoursesEditForm,
  },
  data() {
    return {
      trainingCourseData: {
        name: '',
        description: '',
        department: null,
        date: null,
        endDate: null,
        trainer: null,
        localization: '',
        numberOfParticipants: 0,
        informationMaterials: [],
        afterTrainingMaterials: [],
        priceInGrosze: 0,
        status: 'PUBLIC',
        isFree: false,
      },
      loading: false,
      sendInformationEmail: false,
    }
  },
  methods: {
    submit() {
      const payload = { ...this.trainingCourseData, sendInformationEmail: this.sendInformationEmail }

      this.loading = true
      store
        .dispatch('trainingCourse/addTrainingCourse', payload)
        .then(response => {
          if (response && response.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Utworzono nowe szkolenie',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('/training-courses/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się utworzyć szkolenia.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: response ? 'Koszt szkolenia nie może wynosić 0 zł' : null,
              },
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>

</style>
